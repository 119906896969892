import { useState } from "react";
import { Search, MapPin, Package } from "lucide-react";
import useAxios from "axios-hooks";
import StaticMap from "../google-maps/static-map";

type Product = {
  id: number;
  name: string;
  stock: number;
  price: number;
};

export default function PharmacyFinder() {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState<any[]>([]);

  const [location, setLocation] = useState<{ lat: number; lng: number } | null>(
    null
  );
  const [isDetectingLocation, setIsDetectingLocation] = useState(false);

  const [{ data, loading, error }, executeFetch] = useAxios(
    {
      url: `https://backend.djarapharma.com/public/store/search-nearest/inventory`,
      method: "POST",
      withCredentials: true,
    },
    { manual: true }
  );

  const detectLocation = () => {
    setIsDetectingLocation(true);

    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          setLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });

          const res = await executeFetch({
            data: {
              lat: position.coords.latitude.toString(),
              long: position.coords.longitude.toString(),
              q: searchQuery,
            },
          });

          setIsDetectingLocation(false);
          setSearchResults(res.data?.stores || []);
          // Here you would typically send the location to your server
          console.log("Location detected:", position.coords);
        },
        (error) => {
          setIsDetectingLocation(false);
          console.error("Error:", error);
        }
      );
    } else {
      setIsDetectingLocation(false);
    }
  };

  const handleSearch = async (e: React.FormEvent) => {
    e.preventDefault();
    // Simulating API call with mock data
    detectLocation();
  };

  return (
    <div className="container mx-auto p-4 max-w-6xl">
      <h1 className="text-2xl font-bold mb-6">Pharmacy Finder</h1>

      <form onSubmit={handleSearch} className="flex gap-2 mb-8">
        <input
          type="text"
          placeholder="Enter product name"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="flex-grow px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <button
          type="submit"
          className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        >
          <Search className="inline-block mr-2 h-4 w-4" />
          Search
        </button>
      </form>

      {searchResults.length > 0 && (
        <div className="flex flex-col lg:flex-row gap-6 p-4">
          <div className="w-full lg:w-1/3 lg:max-w-md">
            <h3 className="text-xl font-semibold mb-4">Search Results</h3>
            <div className="space-y-4">
              {searchResults.map((pharmacy) => (
                <div
                  key={pharmacy.id}
                  className="bg-gray-50 rounded-lg overflow-hidden shadow-sm"
                >
                  <a
                    target="_blank"
                    href={`https://www.google.com/maps?q=${pharmacy?.default_location_lat},${pharmacy?.default_location_long}`}
                  >
                    <div className="px-4 py-3 bg-gray-100">
                      <h4 className="text-lg font-medium text-gray-900">
                        {pharmacy.name}
                      </h4>
                    </div>

                    <div className="px-4 py-3">
                      <p className="text-sm text-gray-600 flex items-center">
                        <MapPin className="mr-1 h-4 w-4 flex-shrink-0" />
                        <span className="truncate">{pharmacy.address}</span>
                      </p>
                      <p className="text-sm text-gray-600">
                        Distance: {pharmacy.distance}
                      </p>
                      <h5 className="text-sm font-medium text-gray-900 mt-2 mb-1">
                        Available Products:
                      </h5>
                      <ul className="space-y-1">
                        {pharmacy.products.map((product: any) => (
                          <li
                            key={product.id}
                            className="text-sm flex flex-wrap justify-between items-center"
                          >
                            <span className="flex items-center mr-2">
                              <Package className="mr-1 h-3 w-3 flex-shrink-0" />
                              <span className="truncate">{product.title}</span>
                            </span>
                            <span className="flex-shrink-0 mt-1 sm:mt-0">
                              <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 mr-1">
                                Stock: {product.stock}
                              </span>
                              <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
                                ${product.price?.toFixed(2) ?? "N/A"}
                              </span>
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
          <div className="w-full lg:w-2/3 mt-6 lg:mt-0">
            <h3 className="text-xl font-semibold mb-4">Map</h3>
            <div
              className="bg-gray-200 rounded-lg overflow-hidden"
              style={{ aspectRatio: "16/9" }}
            >
              <StaticMap
                markers={[
                  {
                    lat: -26.102791254605815,
                    lng: 27.909706657179424,
                    label: "test",
                  },
                ]}
                apiKey="AIzaSyDjH6mp-hyG-Ef0Hs_plQfzKgVtJYV2gNI"
              />
            </div>
          </div>
        </div>
      )}

      {searchResults?.length === 0 && (
        <p className="text-center text-gray-500 mt-8">
          Enter a product name and click search to find nearby pharmacies.
        </p>
      )}
    </div>
  );
}
