/**
 * v0 by Vercel.
 * @see https://v0.dev/t/gWHu49fAYBg
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */
import { Button } from "@/components/ui/button";
import HomeScreenIllustration from "../assets/home_screen.svg";

export default function HeroSection() {
  return (
    <div
      id="home"
      className="container flex flex-col items-center h-screen justify-between  px-4 py-8 bg-white"
    >
      <div className="flex bg-gradient-to-r items-center justify-between w-[100%] h-screen">
        <div className=" container flex-1 space-y-4 text-center md:text-left">
          <h1 className="text-7xl font-bold text-green-600">
            Managing Pharmacy, Made Simple
          </h1>
          <p className="text-lg text-gray-600">
            We empower local pharmacies with the tools and technology to
            increase patients' access to low cost, high quality medications.
          </p>
          <div className="flex justify-center md:justify-start space-x-4">
            <Button className="bg-green-600 text-white">
              <PlayIcon className="mr-2" />
              Enquire{"\n                "}
            </Button>
          </div>
        </div>
        <div className=" flex-1  ">
          <div className="">
            <img
              alt="Person illustration"
              className=""
              height="150"
              src={HomeScreenIllustration}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function PhoneIcon(props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
    </svg>
  );
}

function PlayIcon(props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polygon points="6 3 20 12 6 21 6 3" />
    </svg>
  );
}
