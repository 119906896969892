import { Button } from "@/components/ui/button";

import { CheckCircle } from "lucide-react";

export default function InventoryFinanceSection() {
  return (
    <section className="bg-gradient-to-r from-blue-50 to-white py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8 items-center">
          <div>
            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
              Unlock Your Inventory's Potential
            </h2>
            <p className="mt-4 text-lg text-gray-500">
              Our inventory financing service provides the working capital you
              need to stock up, fulfill orders, and grow your business without
              constraints.
            </p>
            <div className="mt-8 space-y-4">
              {[
                "Flexible terms tailored to your business cycle",
                "Quick approval process",
                "Competitive rates",
                "No hidden fees",
              ].map((feature) => (
                <div key={feature} className="flex items-center">
                  <CheckCircle className="h-5 w-5 text-green-500" />
                  <span className="ml-2 text-gray-700">{feature}</span>
                </div>
              ))}
            </div>
            <div className="mt-10">
              <Button className="bg-blue-600 text-white hover:bg-blue-700">
                Get Started Today
              </Button>
            </div>
          </div>
          <div className=" bg-white shadow-xl rounded-lg overflow-hidden ">
            <img
              className="w-full"
              src="https://res.cloudinary.com/dxn1ek6cy/image/upload/v1726310244/327020463_3262120007335969_6746737685749464215_n.jpg"
              alt="Sunset in the mountains"
            />
            <div className="px-6 py-4">
              <div className="font-bold text-xl mb-2">H. Solution Pharmacy</div>
              <p className="text-lg font-medium text-gray-900">
                "DjaraPharma's financing helped us double our inventory and
                sales in just 6 months!"
              </p>
            </div>
            
          </div>
          {/* <div className="mt-10 lg:mt-0">
            <Card className="bg-white shadow-xl rounded-lg overflow-hidden">
              <CardContent className="p-8">
                <img
                  src="/placeholder.svg?height=300&width=400"
                  alt="Inventory Finance Illustration"
                  className="w-full h-auto"
                />
                <div className="mt-6 text-center">
                  <p className="text-lg font-medium text-gray-900">
                    "InventCo's financing helped us double our inventory and
                    sales in just 6 months!"
                  </p>
                  <p className="mt-2 text-gray-500">
                    - Jane Doe, CEO of GrowthRetail
                  </p>
                </div>
              </CardContent>
            </Card>
          </div> */}
        </div>
      </div>
    </section>
  );
}
