/**
 * v0 by Vercel.
 * @see https://v0.dev/t/sXAvye5H6ae
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";

export default function Footer() {
  return (
    <footer id="contact" className="bg-gray-50 text-gray-300 py-12 md:py-16">
      <div className="container max-w-6xl grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="space-y-4">
          <h3 className="text-lg text-gray-500 font-semibold">Contact Us</h3>
          <div className="space-y-2">
            <p className="text-gray-500">Adjacent Ministerial Complex</p>
            <p className="text-gray-500">Congo Town, Monrovia, Liberia</p>
            <p className="text-gray-500">info@djarapharma.com</p>
          </div>
        </div>
        <div>
          <h3 className="text-lg text-gray-500 font-semibold">
            Send Us a Message
          </h3>
          <form className="space-y-4">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div>
                <Label className="text-gray-500" htmlFor="name">
                  Name
                </Label>
                <Input id="name" placeholder="Enter your name" />
              </div>
              <div>
                <Label className="text-gray-500" htmlFor="email">
                  Email
                </Label>
                <Input
                  id="email"
                  className="placeholder-red-500"
                  placeholder="Enter your email"
                  type="email"
                />
              </div>
            </div>
            <div>
              <Label className="text-gray-500" htmlFor="message">
                Message
              </Label>
              <Textarea
                className="min-h-[120px]"
                id="message"
                placeholder="Enter your message"
              />
            </div>
            <Button className="w-full bg-[#66cc66]" type="submit">
              Submit
            </Button>
          </form>
        </div>
      </div>
    </footer>
  );
}
