/**
 * v0 by Vercel.
 * @see https://v0.dev/t/ale15m7Nh9d
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */
export default function FeaturesSection() {
  return (
    <section id="features" className="w-full py-12 md:py-24 lg:py-32 bg-gray-100 dark:bg-gray-800">
      <div className="container px-4 md:px-6">
        <div className="flex flex-col items-center justify-center space-y-4 text-center">
          <div className="space-y-2">
            <div className="inline-block rounded-lg bg-gray-100 px-3 py-1 text-sm dark:bg-gray-700">
              Features
            </div>
            <h2 className="text-[#6bb762] text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">
              Powerful Features
            </h2>
            <p className="max-w-[700px] text-gray-500 md:text-xl dark:text-gray-400">
              Discover the features that make our platform the best choice for
              your business.
            </p>
          </div>
        </div>
        <div className="mx-auto grid max-w-5xl grid-cols-1 gap-6 py-12 sm:grid-cols-2 md:grid-cols-3 lg:gap-12">
          <div className="flex flex-col items-start rounded-lg bg-white p-6 shadow-md transition-all hover:scale-[1.02] hover:shadow-lg dark:bg-gray-950 dark:hover:bg-gray-900">
            <BoltIcon className="mb-4 h-8 w-8 text-primary" />
            <h3 className="text-[#6bb762] mb-2 text-xl font-bold">
              Inventory Management
            </h3>
            <p className="text-gray-500 dark:text-gray-400">
              Manage your inventory with ease using our platform
            </p>
          </div>
          <div className="flex flex-col items-start rounded-lg bg-white p-6 shadow-md transition-all hover:scale-[1.02] hover:shadow-lg dark:bg-gray-950 dark:hover:bg-gray-900">
            <ShieldIcon className="mb-4 h-8 w-8 text-primary" />
            <h3 className="text-[#6bb762] mb-2 text-xl font-bold">Stock Up</h3>
            <p className="text-gray-500 dark:text-gray-400">
              Keep your shelves stocked up by stocking up with us
            </p>
          </div>
          <div className="flex flex-col items-start rounded-lg bg-white p-6 shadow-md transition-all hover:scale-[1.02] hover:shadow-lg dark:bg-gray-950 dark:hover:bg-gray-900">
            <AlignCenterHorizontalIcon className="mb-4 h-8 w-8 text-primary" />
            <h3 className="text-[#6bb762] mb-2 text-xl font-bold">
              Store Analytics
            </h3>
            <p className="text-gray-500 dark:text-gray-400">
              Get tailored analytics of your store and start making data driven
              decisions.
            </p>
          </div>
          <div className="flex flex-col items-start rounded-lg bg-white p-6 shadow-md transition-all hover:scale-[1.02] hover:shadow-lg dark:bg-gray-950 dark:hover:bg-gray-900">
            <RocketIcon className="mb-4 h-8 w-8 text-primary" />
            <h3 className="text-[#6bb762] mb-2 text-xl font-bold">POS</h3>
            <p className="text-gray-500 dark:text-gray-400">
              Digitize your sale process with our Point of Sale system
            </p>
          </div>
          <div className="flex flex-col items-start rounded-lg bg-white p-6 shadow-md transition-all hover:scale-[1.02] hover:shadow-lg dark:bg-gray-950 dark:hover:bg-gray-900">
            <ScaleIcon className="mb-4 h-8 w-8 text-primary" />
            <h3 className="text-[#6bb762] mb-2 text-xl font-bold">
              Multiple Stores
            </h3>
            <p className="text-gray-500 dark:text-gray-400">
              Manage multiple location inventory from one central place.
            </p>
          </div>
          {/* <div className="flex flex-col items-start rounded-lg bg-white p-6 shadow-md transition-all hover:scale-[1.02] hover:shadow-lg dark:bg-gray-950 dark:hover:bg-gray-900">
            <BriefcaseIcon className="mb-4 h-8 w-8 text-primary" />
            <h3 className="text-[#6bb762] mb-2 text-xl font-bold">Enterprise-Grade</h3>
            <p className="text-gray-500 dark:text-gray-400">
              Our platform is trusted by leading enterprises, with the features
              and support you need to succeed.
            </p>
          </div> */}
        </div>
      </div>
    </section>
  );
}

function AlignCenterHorizontalIcon(props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M2 12h20" />
      <path d="M10 16v4a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-4" />
      <path d="M10 8V4a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2v4" />
      <path d="M20 16v1a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2v-1" />
      <path d="M14 8V7c0-1.1.9-2 2-2h2a2 2 0 0 1 2 2v1" />
    </svg>
  );
}

function BoltIcon(props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z" />
      <circle cx="12" cy="12" r="4" />
    </svg>
  );
}

function BriefcaseIcon(props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M16 20V4a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16" />
      <rect width="20" height="14" x="2" y="6" rx="2" />
    </svg>
  );
}

function RocketIcon(props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M4.5 16.5c-1.5 1.26-2 5-2 5s3.74-.5 5-2c.71-.84.7-2.13-.09-2.91a2.18 2.18 0 0 0-2.91-.09z" />
      <path d="m12 15-3-3a22 22 0 0 1 2-3.95A12.88 12.88 0 0 1 22 2c0 2.72-.78 7.5-6 11a22.35 22.35 0 0 1-4 2z" />
      <path d="M9 12H4s.55-3.03 2-4c1.62-1.08 5 0 5 0" />
      <path d="M12 15v5s3.03-.55 4-2c1.08-1.62 0-5 0-5" />
    </svg>
  );
}

function ScaleIcon(props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m16 16 3-8 3 8c-.87.65-1.92 1-3 1s-2.13-.35-3-1Z" />
      <path d="m2 16 3-8 3 8c-.87.65-1.92 1-3 1s-2.13-.35-3-1Z" />
      <path d="M7 21h10" />
      <path d="M12 3v18" />
      <path d="M3 7h2c2 0 5-1 7-2 2 1 5 2 7 2h2" />
    </svg>
  );
}

function ShieldIcon(props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M20 13c0 5-3.5 7.5-7.66 8.95a1 1 0 0 1-.67-.01C7.5 20.5 4 18 4 13V6a1 1 0 0 1 1-1c2 0 4.5-1.2 6.24-2.72a1.17 1.17 0 0 1 1.52 0C14.51 3.81 17 5 19 5a1 1 0 0 1 1 1z" />
    </svg>
  );
}
