import FeaturesSection from "./sections/features";
import Footer from "./sections/footer";
import HeroSection from "./sections/hero";
import InventoryFinanceSection from "./sections/inventory-finance";
import Navbar from "./sections/navbar";
import PharmacyFinderSection from "./sections/pharmacy-finder";

function App() {
  return (
    <>
      <Navbar />
      <HeroSection />
      <FeaturesSection />
      <InventoryFinanceSection />
      <PharmacyFinderSection />
      <Footer />
    </>
  );
}

export default App;
