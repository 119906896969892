import { Button } from "@/components/ui/button";
import { useState, useEffect } from "react";
import logo from "../assets/logo-v2.svg";

function Navbar() {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav
      className={` mx-auto px-6 py-2 flex justify-around items-center ${
        isSticky
          ? "sticky top-0 bg-gradient-to-r from-10% from-[#c6e0c3] to-90% to-[#99eb8f] z-10"
          : "bg-white"
      }`}
    >
      <a className="text-white text-xl font-bold" href="#home">
        <img src={logo} width={"60px"} alt="Logo" />
      </a>
      <div className="flex space-x-4">
        <a
          className={`${
            isSticky
              ? "text-white hover:text-gray-300"
              : "text-[#6bb762] hover:text-gray-300"
          }`}
          href="#home"
        >
          HOME
        </a>
        <a
          className={`${
            isSticky
              ? "text-white hover:text-gray-300"
              : "text-[#6bb762] hover:text-gray-300"
          }`}
          href="#features"
        >
          FEATURES
        </a>
        <a
          className={`${
            isSticky
              ? "text-white hover:text-gray-300"
              : "text-[#6bb762] hover:text-gray-300"
          }`}
          href="#contact"
        >
          CONTACT
        </a>
      </div>
      <div className="flex space-x-4">
        <Button
          variant="outline"
          className="bg-white text-green-700 hover:bg-green-50"
        >
          <a
            href="https://app.djarapharma.com/login"
            target="_blank"
            rel="noreferrer"
          >
            Sign In
          </a>
        </Button>
        <Button className="bg-green-700 text-white hover:bg-green-800">
          <a
            href="https://app.djarapharma.com/register"
            target="_blank"
            rel="noreferrer"
          >
            Sign Up
          </a>
        </Button>
        <Button className="bg-white text-[#66cc66] hover:bg-gray-200">
          <a href="#contact">Get in touch</a>
        </Button>
      </div>
    </nav>
  );
}

export default Navbar;
