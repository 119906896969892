import PharmacyFinder from "@/components/pharmacy-finder/pharmacy-finder";

const PharmacyFinderSection = () => {
 
  return (
    <section className="py-12 md:py-24 lg:py-32 mb-16">
      <h2 className="text-3xl font-bold text-center mb-8">
        Use Our Pharmacy Finder
      </h2>
      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <PharmacyFinder />
      </div>
    </section>
  );
};

export default PharmacyFinderSection;
