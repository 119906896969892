import React, { useMemo } from "react";
type props = {
  markers: { lat: number; lng: number; label: string }[];
  height?: number;
  width?: number;
  zoom?: number;
  apiKey: string;
};
const StaticMap: React.FC<props> = ({
  apiKey,
  zoom = 13,
  width = 600,
  height = 400,
  markers = [],
}) => {
  const baseUrl = "https://maps.googleapis.com/maps/api/staticmap";

  const { center, bounds } = useMemo(() => {
    if (markers.length === 0) {
      return { center: { lat: 0, lng: 0 }, bounds: null };
    }

    let minLat = markers[0].lat;
    let maxLat = markers[0].lat;
    let minLng = markers[0].lng;
    let maxLng = markers[0].lng;

    markers.forEach((marker) => {
      minLat = Math.min(minLat, marker.lat);
      maxLat = Math.max(maxLat, marker.lat);
      minLng = Math.min(minLng, marker.lng);
      maxLng = Math.max(maxLng, marker.lng);
    });

    const center = {
      lat: (minLat + maxLat) / 2,
      lng: (minLng + maxLng) / 2,
    };

    const bounds = {
      southwest: { lat: minLat, lng: minLng },
      northeast: { lat: maxLat, lng: maxLng },
    };

    return { center, bounds };
  }, [markers]);

  const markerString = markers
    .map(
      (marker, index) =>
        `markers=color:red%7Clabel:${index + 1}%7C${marker.lat},${marker.lng}`
    )
    .join("&");

  const mapUrl = useMemo(() => {
    let url = `${baseUrl}?center=${center.lat},${center.lng}&zoom=${zoom}&size=${width}x${height}&${markerString}&key=${apiKey}`;

    if (bounds && markers.length > 1) {
      url = `${baseUrl}?size=${width}x${height}&${markerString}&key=${apiKey}`;
      url += `&visible=${bounds.southwest.lat},${bounds.southwest.lng}|${bounds.northeast.lat},${bounds.northeast.lng}`;
    }

    return url;
  }, [
    center,
    zoom,
    width,
    height,
    markerString,
    apiKey,
    bounds,
    markers.length,
  ]);

  return (
    <div className="static-map">
      <img
        src={mapUrl}
        alt="Static Map"
        width={width}
        height={height}
        className="rounded-lg shadow-md"
      />
      <div className="mt-4">
        <h3 className="text-lg font-semibold mb-2">Markers:</h3>
        <ul className="list-disc pl-5">
          {markers.map((marker, index) => (
            <li key={index} className="mb-1">
              {marker.label || `Marker ${index + 1}`}: {marker.lat.toFixed(4)},{" "}
              {marker.lng.toFixed(4)}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default StaticMap;
